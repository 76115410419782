import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo:null
  },
  getters: {
    username(state){
      return state.userInfo?.nickName;
      // if(state.userInfo){
      //   return state.userInfo.username;
      // }
    },
  },
  mutations: {
    SET_USER_INFO(state, payload){
      state.userInfo = payload;
    }
  },
  actions: {
  },
  modules: {
  }
})
