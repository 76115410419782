import axios from "axios";
const products = {

    // 根据游戏id获取商品列表
    queryProductPageList(data) {
        return axios({
            url: '/good/product/QueryPageList',
            method: 'POST',
            data
        })
    },
    //根据游戏id获取类型
    queryGategoryByGame(params) {
        return axios({
            url: '/good/gamecategory/QueryGategoryByGame?' + params,
            method: 'GET',
        })
    },
    //根据类型id获取类型子项
    queryEntriechildcategoryByCategoryId(data) {
        return axios({
            url: '/good/entriechildcategory/QueryListAysnc',
            method: 'POST',
            data
        })
    },
    //查询商品
    queryProduct(id) {
        return axios({
            url: '/good/product/QueryAysnc?id=' + id,
            method: 'GET',
        })
    },
    //查询商品议价
    queryProductbargain(id) {
        return axios({
            url: '/good/productbargain/QueryAysnc?id=' + id,
            method: 'GET',
        })
    },
    //议价
    productbargainCreate(data) {
        return axios({
            url: '/good/productbargain/Create',
            method: 'POST',
            data
        })

    },
    //获取最新议价
    queryLatestAysnc(productUniqueNo) {
        return axios({
            url: '/good/productbargain/QueryLatestAysnc?productUniqueNo=' + productUniqueNo,
            method: 'GET',

        })

    },
    //收藏商品
    createProductcollect(productUniqueNo) {
        return axios({
            url: '/good/productcollect/Create?productUniqueNo=' + productUniqueNo,
            method: 'GET',
        })
    },
    //取消收藏
    delProductcollect(productUniqueNo) {
        return axios({
            url: '/good/productcollect/Del?productUniqueNo=' + productUniqueNo,
            method: 'GET',
        })
    },
    //获取收藏简要列表
    querySingleListProductcollect() {
        return axios({
            url: '/good/productcollect/QuerySingleListAysnc',
            method: 'GET',
        })
    },
    //获取收藏列表
    queryListProductcollect(data) {
        return axios({
            url: '/good/productcollect/QueryListAysnc',
            method: 'POST',
            data
        })
    },
    //获取收藏分页列表
    queryPageListProductcollect(data) {
        return axios({
            url: '/good/productcollect/QueryPageListAysnc',
            method: 'POST',
            data
        })
    },
    //获取账号信息
    queryAccountInfoListAysnc(gameId) {
        return axios({
            url: '/good/gameAccountInfo/QueryListAysnc?gameId=' + gameId,
            method: 'GET',
        })
    },
    //上传文件
    upload(data) {
        return axios({
            url: '/good/game/GeneratePostPolicy/GeneratePostPolicy',
            method: 'POST',
            data
        })
    },
    //添加商品
    addProduct(data) {
        return axios({
            url: '/good/product/H5CreateH5',
            method: 'POST',
            data
        })
    },
    //获取功能数量
    queryActionNumber() {
        return axios({
            url: '/good/product/QueryActionNumber',
            method: 'GET',
        })
    },
    //获取发布的账号
    querySalesList(data) {
        return axios({
            url: '/good/product/QuerySalesList',
            method: 'POST',
            data
        })
    },
    //修改数据状态
    updateProductStatus(data) {
        return axios({
            url: '/good/product/UpdateProductStatus',
            method: 'POST',
            data
        })
    },
    //修改商品议价状态
    updateProductIsPremium(productUniqueNo) {
        return axios({
            url: '/good/product/UpdateProductIsPremium?productUniqueNo=' + productUniqueNo,
            method: 'GET',
        })
    },
    //修改商品价格
    updateProductPrice(data) {
        return axios({
            url: '/good/product/UpdateProductPrice',
            method: 'POST',
            data
        })
    },
    //重新提交商品信息
    replayProductTransactionInfo(data) {
        return axios({
            url: '/good/product/ReplayProductTransactionInfo',
            method: 'POST',
            data
        })
    },
    // 议价功能分页列表
    productbargainQueryPageList(data) {
        return axios({
            url: '/good/productbargain/QueryPageList',
            method: 'POST',
            data
        })
    },
    //获取最新商品分页列表
    productnewQueryPageList(data) {
        return axios({
            url: '/good//productnew/QueryPageList',
            method: 'POST',
            data
        })
    },
    //点击增加商品热度
    addClickNum(productId) {
        return axios({
            url: '/good/product/addClickNum?productId=' + productId,
            method: 'GET',
        })
    },


    //查询通知分页列表
    noticePageList(data) {
        return axios({
            url: '/good/notice/QueryPageList',
            method: 'POST',
            data
        })
    },
    //标记消息为已读
    readNotice(id) {
        return axios({
            url: '/good/notice/ReadNotice?id=' + id,
            method: 'GET',
        })
    },
    //标记所有消息为已读
    readAllNotice(){
        return axios({
            url: '/good/notice/ReadAllNotice',
            method: 'GET',
        })
    },
    //是否存在未读通知
    haveWaitReadNotice(){
        return axios({
            url: '/good/notice/HaveWaitReadNotice',
            method: 'GET',
        })
    },
    //添加咨询记录
    productCustomerCreate(data)
    {
        return axios({
            url: '/good/productcustomer/Create',
            method: 'POST',
            data
        })
    },
    //删除咨询记录
    productCustomerDelete(id)
    {
        return axios({
            url: '/good/productcustomer/Delete?id='+id,
            method: 'GET'
        })
    },
    //获取咨询记录分页列表
    productCustomerQueryPageList(data)
    {
        return axios({
            url: '/good/productcustomer/QueryPageList',
            method: 'POST',
            data
        })
    },
      //添加浏览历史
      productHistoryCreate(data)
      {
          return axios({
              url: '/good/producthistory/Create',
              method: 'POST',
              data
          })
      },
     
      //获取浏览历史分页列表
      productHistoryQueryPageList(data)
      {
          return axios({
              url: '/good/producthistory/QueryPageList',
              method: 'POST',
              data
          })
      },
      //获取游戏资费信息
      queryGameRateListAysnc(gameId){
        return axios({
            url: '/good/gamerate/QueryListAysnc?gameId='+gameId,
            method: 'GET'
        }) 
      }
}

export default products;