//用哪个组件引哪个组件 每用一个都得引
import Vue from 'vue'
import { 
    Button, Container, Header, Aside, Main, Menu, Submenu, MenuItem,
    Table, TableColumn, Pagination, Input, Form, FormItem, Message, MessageBox,
    Dialog,Select,Option,Radio,RadioGroup, Upload, Avatar,Card, Breadcrumb, BreadcrumbItem, Popover, Tag, DatePicker, Image, Cascader, CarouselItem, Carousel, Checkbox, Steps, Step,
} from 'element-ui'

Vue.use(Button)
Vue.use(Container)
Vue.use(Header)
Vue.use(Aside)
Vue.use(Main)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Pagination)
Vue.use(Input)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Dialog)
Vue.use(Select)
Vue.use(Option)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Upload)
Vue.use(Avatar)
Vue.use(Card)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Popover)
Vue.use(Tag)
Vue.use(DatePicker)
Vue.use(Image)
Vue.use(Cascader)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Checkbox)
Vue.use(Steps)
Vue.use(Step)




Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;
