import axios from "axios";
const users = {
    //登录
    login(data) {
        return axios({
            url: '/common/user/LoginByPwd',
            method: 'POST',
            data
        })
    },
    // 注册
    goRegister(data) {
        return axios({
            url: '/common/user/Register',
            method: 'POST',
            data
        })
    },
    getAll(data) {
        return axios({
            url: '/common/user/GetPagedList',
            method: 'POST',
            data
        })
    },

    sendSmsCode(params) {
        return axios({
            url: '/common/sms/SendSmsCode?phone=' + params.phone + '&codeType=' + params.codeType,
            method: 'GET',
        })
    },
    getSmsCode(params) {
        return axios({
            url: '/common/sms/GetSmsCode?phone=' + params.phone + '&codeType=' + params.codeType,
            method: 'GET',
        })
    },
    loginBySmsCode(data) {
        return axios({
            url: '/common/user/LoginBySmsCode',
            method: 'POST',
            data
        })
    },
    // 修改密码
    goForgetPwd(data) {
        return axios({
            url: '/common/user/UpdatePwd',
            method: 'POST',
            data
        })
    },

    // 获取用户信息
    queryUserById(id) {
        return axios({
            url: '/common/user/QueryUserById?id=' + id,
            method: 'GET',
        })
    },

    // 获取用户信息
    currentUserInfo() {
        return axios({
            url: '/common/user/QueryUser',
            method: 'GET',
        })
    },
    //根据id集合获取用户信息
    queryUserInfos(data) {
        return axios({
            url: '/common/user/QueryUserInfo',
            method: 'POST',
            data
        })
    },

    // 获取当前客服
    getCurrentCustomer() {
        return axios({
            url: '/common/user/GetCustomer',
            method: 'GET',
        })
    },

    update(data) {
        return axios({
            url: '/video/UpdateVideo',
            method: 'POST',
            data
        })
    },
    del(id) {
        return axios({
            url: `/video/DelVideo?id=${id}`,
            method: 'GET',
        })
    },
    //修改昵称
    updateNickName(nickName) {
        return axios({
            url: `/common/user/UpdateNickName?nickName=${nickName}`,
            method: 'GET',
        })
    },
    //修改头像
    updateHeadImg(headImg) {
        return axios({
            url: `/common/user/UpdateHeadImg?headImg=${headImg}`,
            method: 'GET',
        })
    },
    // 创建用户实名
    createUserrealname(data) {
        return axios({
            url: '/common/userrealname/Create',
            method: 'POST',
            data
        })
    },
    //查询用户实名
    queryUserrealname() {
        return axios({
            url: `/common/userrealname/Query`,
            method: 'GET',
        })
    },
    // 验证旧手机
    checkOldPhone(data) {
        return axios({
            url: '/common/user/VerifyPhoneCode',
            method: 'POST',
            data
        })
    },
    // 换绑
    checkNewPhone(data) {
        return axios({
            url: '/common/user/UpdatePhone',
            method: 'POST',
            data
        })
    },
    //获取所有客服
    getAllCus(data) {
        return axios({
            url: '/common/user/GetPagedList',
            method: 'POST',
            data
        })
    },
    //添加出售资料
    createUseridcard(data) {
        return axios({
            url: '/common/useridcard/Create',
            method: 'POST',
            data
        })
    },
    //获取出售资料
    queryUseridcard() {
        return axios({
            url: '/common/useridcard/Query',
            method: 'GET'
        })
    },
}

export default users;