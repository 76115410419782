import Vue from 'vue'
import VueRouter from 'vue-router'
//引入api
import api from '@/api';
//引入仓库对象
import store from '@/store';
//引入组件
import { MessageBox } from 'element-ui';
import { Message } from 'element-ui';
// 引入
import LoginView from '../views/login/LoginView.vue';
import ForgetPassWord from '../views/login/forgetPassWord.vue';
import Register from '../views/login/register.vue';
import HomeView from '../views/home/HomeView.vue';
import HomeIndex from '../views/home/index.vue';

//引入通信
import signalr from "@/utils/signalR.js";
// 解决页面报红错
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

//路由懒加载：对路由组件进行懒加载
const NotFound = () => import('@/views/not-found/NotFound.vue');
const Game = () => import('@/views/game/GameView.vue');
const AccountSell = () => import('@/views/transactions/accountSell.vue');
const SelectGame = () => import('@/views/game/SelectGame.vue');
const GameDetailsView = () => import('@/views/game/GameDetailsView.vue');

const HelpCenter = () => import('@/views/helpCenter/HelpCenter.vue');
const HelpDetail = () => import('@/views/helpCenter/helpDetail/index.vue');
const HelpDetails = () => import('@/views/helpCenter/helpDetail/HelpDetail.vue');
const MyAccountIndex = () => import('@/views/personalCenter/index.vue');
const MyAccount = () => import('@/views/personalCenter/myAccount.vue');
const Seller = () => import('@/views/personalCenter/seller/Seller.vue');
const SellInfo = () => import('@/views/personalCenter/seller/SellInfo.vue');
const Bargain = () => import('@/views/personalCenter/seller/Bargain.vue');
const Buyer = () => import('@/views/personalCenter/buyer/index.vue');
const PayDetail = () => import('@/views/personalCenter/buyer/PayDetail.vue');
const BuyBargain = () => import('@/views/personalCenter/buyer/Bargain.vue');
const MyCollection = () => import('@/views/personalCenter/myCollection/index.vue');
const PersonalInfo = () => import('@/views/personalCenter/personalInfo/index.vue');
const RNameAuthentication = () => import('@/views/personalCenter/personalInfo/RNameAuthentication.vue');
const ModifyLoginPwd = () => import('@/views/personalCenter/personalInfo/ModifyLoginPwd.vue');
const ChangeTelphone = () => import('@/views/personalCenter/personalInfo/ChangeTelphone.vue');
const ContractCenter = () => import('@/views/personalCenter/contractCenter/index.vue');
const Chat = () => import('@/views/personalCenter/chat/Chat.vue');
const NewChat = () => import('@/views/personalCenter/chat/NewChat.vue');
const ProductDetail = () => import('@/views/personalCenter/buyer/productDetail.vue');
//身份证
const IdCard = () => import('@/views/personalCenter/personalInfo/IdCard.vue');

// 交易中心
const TradeTip = () => import('@/views/tradeTip/index.vue');
const TradeTips = () => import('@/views/tradeTip/TradeTip.vue');
// 黑户
const Blacknumverify = () => import('@/views/blacknumverify/Blacknumverify.vue');

const Vouch = () => import('@/views/vouch/Vouch.vue');
const VouchDetails = () => import('@/views/vouch/VouchDetails.vue');
// 代肝区
const TraningAllGame = () => import('@/views/training/receivingOrders/AllGame.vue');
const TraningSelectGame = () => import('@/views/training/receivingOrders/SelectGame.vue');
const TraningOrderDetailsView = () => import('@/views/training/receivingOrders/OrderDetailsView.vue');
const TraningSellAllGame = () => import('@/views/training/release/TraningSellAllGame.vue');
// 客服中心
const CusSerCenter = () => import('@/views/cusSerCenter/index.vue');
const CusSerList = () => import('@/views/cusSerCenter/cusSerList.vue');
//关于我们
const About = () => import('@/views/about/index.vue');
const Advantage = () => import('@/views/about/advantage.vue');
const Introduce = () => import('@/views/about/introduce.vue');
const Culture = () => import('@/views/about/culture.vue');
// 买家须知
const SellAgreement = () => import('@/views/sellAgreement/SellAgreement.vue');
//支付
const onlineDeal = () => import('@/views/pay/onlineDeal.vue');
const payView = () => import('@/views/pay/payView.vue');
// 公告
const Article = () => import('@/views/article/index.vue');

// 浏览历史
const history = () => import('@/views/personalCenter/history.vue');
// 咨询历史
const serHistory = () => import('@/views/personalCenter/serHistory.vue');
// 消息通知
const siteNotice = () => import('@/views/personalCenter/siteNotice.vue');
Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		redirect: '/home/index'
	},
	{
		path: '/login',
		name: 'login',
		component: LoginView
	},
	{
		path: '/forgetPassWord',
		name: 'forgetPassWord',
		component: ForgetPassWord
	},
	{
		path: '/register',
		name: 'register',
		component: Register
	},

	{
		path: '/helpDetail',
		name: 'HelpDetail',
		component: HelpDetail,
		children: [
			{
				path: 'helpDetails',
				name: 'HelpDetails',
				component: HelpDetails,

			}]
	},
	{
		path: '/home',
		name: 'Home',
		component: HomeView,
		children: [
			//子路由的path不需要斜杠
			{
				path: 'index',
				name: 'index',
				component: HomeIndex,
			},
			{
				path: 'article',
				name: 'article',
				component: Article,
			},
			{
				path: 'game',
				name: 'Game',
				component: Game,
				meta: {
					title: ['首页', '全部游戏']
				}
			},
			{
				path: 'accountSell',
				name: 'AccountSell',
				component: AccountSell,
				meta: {
					title: ['首页', '账号交易', '我要卖号']
				}
			},
			{
				path: 'selectGame',
				name: 'SelectGame',
				component: SelectGame,
				meta: {
					title: ['首页', '选择游戏']
				}
			},
			{
				path: 'sellAgreement',
				name: 'sellAgreement',
				component: SellAgreement,
				meta: {
					title: ['首页', '卖家须知']
				}
			},
			{
				path: 'cusSerCenter',
				name: 'cusSerCenter',
				component: CusSerCenter,
				meta: {
					title: ['首页', '客服中心']
				}
			},
			{
				path: 'cusSerList',
				name: 'cusSerList',
				component: CusSerList,
				meta: {
					title: ['首页', '客服列表']
				}
			},
			{
				path: 'onlineDeal',
				name: 'onlineDeal',
				component: onlineDeal,
				meta: {
					title: ['首页', '支付确认']
				}
			},
			{
				path: 'payView',
				name: 'payView',
				component: payView,
				meta: {
					title: ['首页', '付款']
				}
			},

			{
				path: 'gameDetailsView',
				name: 'GameDetailsView',
				component: GameDetailsView,
				meta: {
					title: ['首页', '选择游戏']
				}
			},


			{
				path: 'helpCenter',
				name: 'helpCenter',
				component: HelpCenter,
				meta: {
					title: ['首页', '帮助中心']
				}
			},
			{
				path: 'blacknumverify',
				name: 'Blacknumverify',
				component: Blacknumverify,
				meta: {
					title: ['首页', '黑号验证']
				}
			},
			{
				path: 'vouch',
				name: 'Vouch',
				component: Vouch,
				meta: {
					title: ['首页', '担保中介']
				}
			},
			{
				path: 'vouchDetails',
				name: 'VouchDetails',
				component: VouchDetails,
				meta: {
					title: ['首页', '担保中介']
				}
			},

			{
				path: 'myAccountIndex',
				name: 'MyAccountIndex',
				component: MyAccountIndex,
				children: [
					{
						path: 'myAccount',
						name: 'MyAccount',
						component: MyAccount,

					},
					{
						path: 'seller',
						name: 'seller',
						component: Seller,

					},
					{
						path: 'sellInfo',
						name: 'sellInfo',
						component: SellInfo,

					},
					{
						path: 'productDetail',
						name: 'productDetail',
						component: ProductDetail,
						
					},
					{
						path: 'bargain',
						name: 'Bargain',
						component: Bargain,

					},
					{
						path: 'buyer',
						name: 'buyer',
						component: Buyer,
					},
					{
						path: 'payDetail',
						name: 'payDetail',
						component: PayDetail,
					},
					{
						path: 'buyBargain',
						name: 'buyBargain',
						component: BuyBargain,
					},
					{
						path: 'myCollection',
						name: 'myCollection',
						component: MyCollection,
					},
					{
						path: 'personalInfo',
						name: 'personalInfo',
						component: PersonalInfo,
					},
					{
						path: 'rNameAuthentication',
						name: 'rNameAuthentication',
						component: RNameAuthentication,
					},
					{
						path: 'modifyLoginPwd',
						name: 'modifyLoginPwd',
						component: ModifyLoginPwd,
					},
					{
						path: 'idCard',
						name: 'idCard',
						component: IdCard,
					},
					
					{
						path: 'changeTelphone',
						name: 'changeTelphone',
						component: ChangeTelphone,
					},
					{
						path: 'chat',
						name: 'chat',
						component: Chat,
					},
					{
						path: 'contractCenter',
						name: 'contractCenter',
						component: ContractCenter,
					},
					{
						path: 'history',
						name: 'history',
						component: history,
					},
					{
						path: 'serHistory',
						name: 'serHistory',
						component: serHistory,
					},
					{
						path: 'siteNotice',
						name: 'siteNotice',
						component: siteNotice,
					},
				]
			},
			{
				path: 'about',
				name: 'about',
				component: About,
				children: [
					{
						path: 'advantage',
						name: 'advantage',
						component: Advantage,

					},
					{
						path: 'introduce',
						name: 'introduce',
						component: Introduce,

					},
					{
						path: 'culture',
						name: 'culture',
						component: Culture,

					},
					// {
					// 	path: 'bargain',
					// 	name: 'Bargain',
					// 	component: Bargain,

					// },
					// {
					// 	path: 'buyer',
					// 	name: 'buyer',
					// 	component: Buyer,
					// },
					// {
					// 	path: 'payDetail',
					// 	name: 'payDetail',
					// 	component: PayDetail,
					// },
					// {
					// 	path: 'buyBargain',
					// 	name: 'buyBargain',
					// 	component: BuyBargain,
					// },
					// {
					// 	path: 'myCollection',
					// 	name: 'myCollection',
					// 	component: MyCollection,
					// },
					// {
					// 	path: 'personalInfo',
					// 	name: 'personalInfo',
					// 	component: PersonalInfo,
					// },
					// {
					// 	path: 'rNameAuthentication',
					// 	name: 'rNameAuthentication',
					// 	component: RNameAuthentication,
					// },
					// {
					// 	path: 'modifyLoginPwd',
					// 	name: 'modifyLoginPwd',
					// 	component: ModifyLoginPwd,
					// },
					// {
					// 	path: 'changeTelphone',
					// 	name: 'changeTelphone',
					// 	component: ChangeTelphone,
					// },
					// {
					// 	path: 'chat',
					// 	name: 'chat',
					// 	component: Chat,
					// },
					// {
					// 	path: 'contractCenter',
					// 	name: 'contractCenter',
					// 	component: ContractCenter,
					// },
				]
			},
			{
				path: 'tradeTip',
				name: 'TradeTip',
				component: TradeTip,
				children: [
					{
						path: 'tradeTips',
						name: 'TradeTips',
						component: TradeTips,

					},

				]
			},
			{
				path: 'traningAllGame',
				name: 'traningAllGame',
				component: TraningAllGame,
				meta: {
					title: ['首页', '代肝专区', '全部游戏']
				}
			},
			{
				path: 'traningSelectGame',
				name: 'traningSelectGame',
				component: TraningSelectGame,
				meta: {
					title: ['首页', '代肝专区', '选中游戏']
				}
			},
			{
				path: 'traningOrderDetailsView',
				name: 'traningOrderDetailsView',
				component: TraningOrderDetailsView,
				meta: {
					title: ['首页', '代肝专区', '选中订单']
				}
			},
			{
				path: 'traningSellAllGame',
				name: 'traningSellAllGame',
				component: TraningSellAllGame,
				meta: {
					title: ['首页', '代肝专区', '发布账号']
				}
			},



		]
	},
	{
		path: '/newChat',
		name: 'newChat',
		component: NewChat,
		meta: {
			title: ['首页', '新聊天室']
		}
	},

	
	
	//404页面路由配置  
	{
		path: '*',
		name: 'NotFound',
		component: NotFound,
	}
]

//创建路由实例对象
const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})
// 全局前置守卫
router.beforeEach(async (to, from, next) => {
	if (to.path.includes('newChat')) {
		var data = signalr.SR;
		if (Object.keys(data).length === 0) {
			//建立数据通信
			await signalr.init('/chathub')
		}
	}
	// 首页
	if (to.path.includes('index')) {
		//进入if, 表示用户想要进入权限页面
		const token = localStorage.token;  // 拿到本地储存的token
		if (token) { //进入if,表示本地存储中有token，说明用户曾经登录过
			// 判断token是否过期
			//通过发送获取用户信息的请求, 将token发送至后端 让后端判断token是否过期
			const res = await api.users.currentUserInfo();  //获取用户信息
			if (res?.code == 200) {  //能够获取到用户信息
				//说明token没有过期
				// 路由里面拿仓库对象 需要引入
				// 将用户信息保存到仓库
				store.commit('SET_USER_INFO', res.content);
				next();
			}
		} else {
			next();
		}
	}
	// 卖号 登录
	if (to.path.includes('accountSell' || 'newChat' || 'cusSerCenter')) {
		//进入if, 表示用户想要进入权限页面
		const token = localStorage.token;  // 拿到本地储存的token
		if (token) { //进入if,表示本地存储中有token，说明用户曾经登录过
			// 判断token是否过期
			//通过发送获取用户信息的请求, 将token发送至后端 让后端判断token是否过期
			const res = await api.users.currentUserInfo();  //获取用户信息
			if (res?.code == 200) {  //能够获取到用户信息
				//说明token没有过期
				// 路由里面拿仓库对象 需要引入
				// 将用户信息保存到仓库
				store.commit('SET_USER_INFO', res.content);
				next();
			}
		} else { //进入else 表示本地存储中没有token，也就意味着当前用户还没有登陆
			// MessageBox.alert('您还未登录,请先登录', '警告', {
			// 	confirmButtonText: '确定',
			// 	callback: action => {
			// 		next('/login');
			// 		// 刷新页面  清空状态机里面的数据
			// 		location.reload();
			// 	}
			// });
			MessageBox.confirm('您还未登录,请先登录', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				next('/login');
				// 刷新页面  清空状态机里面的数据
				location.reload();
			}).catch(() => {
				Message({
					type: 'info',
					message: '已取消删除'
				});
			});
		}
	}
	// 立即购买页面  登录加实名认证
	if (to.path.includes('gameDetailsView')) {
		//进入if, 表示用户想要进入权限页面
		const token = localStorage.token;  // 拿到本地储存的token
		if (token) { //进入if,表示本地存储中有token，说明用户曾经登录过
			// 判断token是否过期
			//通过发送获取用户信息的请求, 将token发送至后端 让后端判断token是否过期
			const res = await api.users.currentUserInfo();  //获取用户信息
			if (res?.code == 200) {  //能够获取到用户信息
				//说明token没有过期
				// 路由里面拿仓库对象 需要引入
				// 将用户信息保存到仓库
				store.commit('SET_USER_INFO', res.content);
				if (res.content?.isRealName) {
					next();
				} else {
					MessageBox.confirm('您还未实名,请先进行实名认证', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						next('/home/myAccountIndex/rNameAuthentication');
					}).catch(() => {
						next('/home/index');
					});
				}
			}
			else {

				MessageBox.confirm('您还未登录,请先登录', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					next('/login');
					// 刷新页面  清空状态机里面的数据
					location.reload();
				}).catch(() => {
					next('/home/index');
					// Message({
					// 	type: 'info',
					// 	message: '已取消删除'
					// });
				});

			}
		} else { //进入else 表示本地存储中没有token，也就意味着当前用户还没有登陆
			MessageBox.confirm('您还未登录,请先登录', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				next('/login');
				// 刷新页面  清空状态机里面的数据
				location.reload();
			}).catch(() => {
				window.close();
			});
		}
	}


	else {
		next();
	}
})
export default router
