// 引入路由对象
import router from "@/router";
import axios from "axios";
// 引入组件
import { MessageBox, Message } from "element-ui";
// 引入组件
const TARGET_URL = 'http://111.9.47.105:38080';  //目标服务器地址 （不需要处理跨域的地址）
const API_URL = process.env.NODE_ENV === "development" ? "/api" : ""; //处理跨域的地址;
// 需要用哪个写哪个
// 基础路径写公共路径部分
axios.defaults.baseURL = API_URL;
// 当请求如果超过5s还没有结束，自动断掉请求
axios.defaults.timeout = 10000;

// 请求拦截器：当请求发送到后端之前， 将其拦截下来  config请求对象
axios.interceptors.request.use(config => {
    var data = process.env.NODE_ENV;
    //将token添加到所有请求头中   
    config.headers.Authorization = localStorage.token;
    //让请求继续往后端发送
    return config;
});

//响应拦截器，当后端返回的结果达到前端页面之前，将其拦截下来
// 处理响应回来的数据格式  将后端返回的数据里的data交给前端：
// res获取响应对象（后端返回给我们的响应对象），响应对象身上有一个res.data的属性，  
axios.interceptors.response.use(res => {
    // 响应成功时触发
    return res.data
}, (err) => {
    //响应失败时触发,
    if (err && err.response && err.response.status) {
        switch (err.response.status) {
            case 401:
                // //可以知道当前请求报401的错误
                // MessageBox.alert('登录已过期,请重新登录', '警告', {
                //     confirmButtonText: '确定',
                //     callback: action => {
                //         // 路由跳转，需要先引入router路由对象
                //      router.replace('/login');
                //      //刷新页面
                //      location.reload();
                //     }
                //   });
                //   添加message属性报错
                // err.message = '身份认证失败';  
                break;
            case 404:
                Message.error('请求资源找不到');
                err.message = '请求资源找不到';
                break;
            case 500:
                err.message = '服务器错误';
                debugger;
                if (err.response.data.code == 5000) {
                    // err.message=err.response.data.message

                    Message.error(err.response.data.message);
                }
                else {
                    Message.error('服务器错误， 请稍后重试');
                }

                // err.message = '服务器错误';
                break;
            case 5000:
                Message.error('服务器错误， 请稍后重试');
                err.message = '服务器错误';
                break;
            default:
                //除了以上三种报错以外，其他报错提示一下信息
                Message.error('网络错误， 请稍后重试');
                err.message = '未知错误';
        }
        // 抛出报错，阻止代码继续往后执行
        // return Promise.reject(err);
       // return Promise.reject(err.message);
    }
    //除了以上三种报错外，其他报错提示一下信息
  //  Message.error('网络错误， 请稍后重试');


  //  err.message = '未知错误';
    // return Promise.reject(err);
    // 在f12里看的
   // return Promise.reject(err.message);
});