import axios from "axios";
const orders = {
  //获取功能数量
  queryActionNumber() {
    return axios({
      url: '/order/order/QueryActionNumber',
      method: 'GET',
    })
  },
  //查询合同分页列表
  contractQueryPageList(data) {
    return axios({
      url: '/order/contract/QueryPageList',
      method: 'POST',
      data
    })
  },
  //获取发布的账号
  queryOrdersList(data) {
    return axios({
      url: '/order/order/QueryH5PageList',
      method: 'POST',
      data
    })
  },
  // 查询订单详情
  queryOrder(id) {
    return axios({
      url: '/order/order/Query?id=' + id,
      method: 'GET',
    })

  },
  //创建h5订单
  payH5(data) {
    return axios({
      url: '/order/order/PayH5',
      method: 'POST',
      data
    })
  },
  //获取支付宝二维码
  aliPagePay(orderId) {
    return axios({
      url: '/order/pay/AliPagePay?orderId=' + orderId,
      method: 'GET',
    })
  },
  //获取微信二维码
  wechatPagePay(orderId) {
    return axios({
      url: '/order/pay/WechatPagePay?orderId=' + orderId,
      method: 'GET',
    })
  },
  //轮询判断是否支付
  IsPay(orderId) {
    return axios({
      url: '/order/pay/IsPay?orderId=' + orderId,
      method: 'GET',
    })
  },
  //根据房间号查询订单
  QueryOrderByGroupRoomD(roomId) {
    return axios({
      url: '/order/order/QueryOrderByGroupRoomD?groupRoomD=' + roomId,
      method: 'GET',
    })
  },

  //根据商品id获取正在进行的订单
  queryOrderByGoodId(goodId) {
    return axios({
      url: '/order/order/QueryByGoodId?goodId=' + goodId,
      method: 'GET',
    })
  },


  //身份证正面验证

  ocrServer(imgUrl) {
    debugger
    return axios({
      url: '/order/realname/OcrServer?imgUrl=' + imgUrl,
      method: 'GET',
    })
  }
}
export default orders;