import axios from "axios";
const users = {
    getAll(data) {
        return axios({
            url: '/message/chatgroup/GetPagedList',
            method: 'POST',
            data
        })
    },
    //添加群组
    add(data) {
        return axios({
            url: '/message/chatgroup/Create',
            method: 'POST',
            data
        })
    },
    update(data) {
        return axios({
            url: '/video/UpdateVideo',
            method: 'POST',
            data
        })
    },
    del(id) {
        return axios({
            url: `/video/DelVideo?id=${id}`,
            method: 'GET',
        })
    },
    //群成员
    getAllMember(params) {
        return axios({
            url: '/message/chatgroupuser/QueryByGroupId?id=' + params.id,
            method: 'GET',
        })
    },
    //好友成员
    getFriendMember(id) {
        return axios({
            url: '/message/chatfirend/Get?id=' + id,
            method: 'GET',
        })
    },


    addMember(data) {
        return axios({
            url: '/message/chatgroupuser/Create',
            method: 'POST',
            data
        })
    },
    // 获取好友列表
    getAllFriends(data) {
        return axios({
            url: '/message/chatfirend/GetPagedList',
            method: 'POST',
            data
        })
    },
    addFriend(data) {
        return axios({
            url: '/message/chatfirend/Create',
            method: 'POST',
            data
        })
    },
    //获取当天所有聊天列表
    getAllChatList() {
        return axios({
            url: '/message/chatlog/QueryChatList',
            method: 'GET',
        })
    },
    // 好友聊天
    // 创建聊天记录
    createFriendChat(data) {
        return axios({
            url: '/message/chatfriendlog/Create',
            method: 'POST',
            data
        })
    },
    // 好友聊天记录
    getFriendChat(params) {
        return axios({
            url: '/message/chatfriendlog/QueryList?id=' + params.id,
            method: 'GET',
        })
    },
    // 创建群主聊天
    createGroupChat(data) {
        return axios({
            url: '/message/chatgrouplog/Create',
            method: 'POST',
            data
        })
    },
    // 群聊天记录
    getGroupChat(params) {
        return axios({
            url: '/message/chatgrouplog/QueryList?id=' + params.id,
            method: 'GET',
        })
    },
    //获取指定朋友分页聊天记录
    chatfriendlogPageList(data) {
        return axios({
            url: '/message/chatfriendlog/QueryPageList',
            method: 'POST',
            data
        })
    },
    //获取指定群分页聊天记录
    chatgrouplogPageList(data) {
        return axios({
            url: '/message/chatgrouplog/QueryPageList',
            method: 'POST',
            data
        })
    },
    //好友已读
    chatfriendlogreadReadMessage(objectId) {
        return axios({
            url: '/message/chatfriendlogread/ReadMessage?objectId=' + objectId,
            method: 'GET',
        })
    },
    //群组已读
    chatgrouplogreadReadMessage(objectId) {
        return axios({
            url: '/message/chatgrouplogread/ReadMessage?objectId=' + objectId,
            method: 'GET',
        })

    },
    //获取未读聊天消息数量
    totalWaitReadNotice()
    {
        return axios({
            url: '/message/chatlog/TotalWaitReadNotice',
            method: 'GET',
        })
    }
}

export default users;