import axios from "axios";
const games = {
    getAll() {
        return axios({
            url: '/good/game/QueryListAysnc',
            method: 'GET',
        })
    },
    query(id) {
        return axios({
            url: '/good/game/QueryAysnc?id=' + id,
            method: 'GET',
        })
    },
    //获取游戏查询列表
    gameQueryList(gameId) {
        return axios({
            url: `/good/gamesearchone/QueryList?gameId=${gameId}`,
            method: 'GET'
        })
    },
    getGameImgList(gameId) {
        return axios({
            url: `/good/gameimg/QueryListAysnc?gameId=${gameId}`,
            method: 'GET'
        })
    },
    // 图片上传
    upload(data) {
        return axios({
            url: '/good/game/GeneratePostPolicy/GeneratePostPolicy',
            method: 'POST',
            data
        })
    },
    //关键字查询
    keyWordSearch(keyWord) {
        return axios({
            url: `/good/game/KeyWordSearch?keyWord=${keyWord}`,
            method: 'GET'
        })
    },

    //获取游戏费率列表
    queryGameRateList(gameId) {
        return axios({
            url: '/good/gamerate/QueryListAysnc?gameId=' + gameId,
            method: 'GET'
        })
    },

    //查询游戏qq群列表
    QueryQqListByGameId(gameid) {
        return axios({
            url: '/good/qq/QueryListAysnc?gameid=' + gameid,
            method: 'GET',
        })

    },

}
export default games;