<template>
  <div>
    <!-- 路由出口 -->
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style  scoped>
@import './styles/common.css';
</style>