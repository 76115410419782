import Vue from 'vue'
// 在vue上挂载一个指量 preventReClick
// vue防止按钮重复点击
const preventReClick = Vue.directive('preventReClick', {
  inserted: function (el, binding) {
    el.addEventListener('click', (e) => {
      debugger;
      if (!el.disabled) {
        el.disabled = true
        setTimeout(() => {
          el.disabled = false
          //设置时间
        }, binding.value || 2000)
      }
      else // disabled为true时，阻止默认的@click事件
      {
        var time=binding.value || 2000;
        alert("操作频繁:"+time/1000+"秒最多操作一次");
        e.preventDefault()
        e.stopPropagation()
      }
     
    }, true
    )
  }
})
export { preventReClick }