
import users from "./modules/users";
import msgs from "./modules/msgs";
import games from "./modules/games";
import orders from "./modules/orders";
import products from "./modules/products";

const api ={
    users,
    msgs,
    games,
    products,
    orders
}

export default api;