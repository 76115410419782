<template>
    <div data-v-34fffed2="" id="app">
        <div data-v-1075ceb3="" data-v-34fffed2="" class="login">
            <div data-v-1075ceb3="" class="left_logo">
                <img
                    data-v-1075ceb3=""
                    class="router-link-active"
                    src="../../assets/loginImg/login.png"
                    alt=""
                    title="返回首页"
                />
            </div>
            <div data-v-1075ceb3="" class="login_box">
                <div data-v-1075ceb3="" class="el-tabs el-tabs--top">
                    <div class="el-tabs__header is-top">
                        <div class="el-tabs__nav-wrap is-top">
                            <div class="el-tabs__nav-scroll">
                                <div
                                    role="tablist"
                                    class="el-tabs__nav is-top"
                                    style="transform: translateX(0px)"
                                >
                                    <div
                                        v-if="currentLoginType == '1'"
                                        class="el-tabs__active-bar is-top"
                                        style="
                                            width: 88px;
                                            transform: translateX(0px);
                                        "
                                    ></div>
                                    <div
                                        v-if="currentLoginType == '2'"
                                        class="el-tabs__active-bar is-top"
                                        style="
                                            width: 88px;
                                            transform: translateX(88px);
                                        "
                                    ></div>

                                    <div
                                        id="tab-forgetPwd"
                                        aria-controls="pane-forgetPwd"
                                        role="tab"
                                        aria-selected="true"
                                        tabindex="0"
                                        class="el-tabs__item is-top"
                                        v-for="item in loginType"
                                        :key="item.id"
                                        :class="
                                            item.id == currentLoginType
                                                ? 'is-active'
                                                : ''
                                        "
                                        @click="changeLoginType(item.id)"
                                    >
                                        {{ item.name }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="el-tabs__content">
                        <!-- 忘记密码 -->
                        <div
                            v-if="currentLoginType == '1'"
                            data-v-1075ceb3=""
                            role="tabpanel"
                            id="pane-forgetPwd"
                            aria-labelledby="tab-forgetPwd"
                            class="el-tab-pane"
                        >
                            <div data-v-1075ceb3="" class="forgetPwd_box box">
                                <el-form
                                    ref="loginFormRef"
                                    :model="loginForm"
                                    class="el-form forgetPwdForm"
                                    :rules="loginRules"
                                >
                                    <el-form-item label="" prop="phone">
                                        <div
                                            data-v-1075ceb3=""
                                            class="el-form-item pwd is-required"
                                        >
                                            <!---->
                                            <div class="el-form-item__content">
                                                <div
                                                    data-v-59f23ef8=""
                                                    data-v-1075ceb3=""
                                                    class="countryCode"
                                                >
                                                    <div
                                                        data-v-59f23ef8=""
                                                        id="countryCode"
                                                        style="width: 82px"
                                                    >
                                                        <div
                                                            data-v-59f23ef8=""
                                                            class="country-box"
                                                        >
                                                            +86
                                                        </div>
                                                    </div>
                                                    <span
                                                        data-v-59f23ef8=""
                                                    ></span>
                                                    <div
                                                        data-v-59f23ef8=""
                                                        class="input-box"
                                                    >
                                                        <div
                                                            data-v-59f23ef8=""
                                                            class="phoneInput el-input el-input--suffix"
                                                        >
                                                            <!---->
                                                            <el-input
                                                                type="text"
                                                                autocomplete="off"
                                                                placeholder="请输入手机号"
                                                                class="el-input__inner"
                                                                v-model="
                                                                    loginForm.phone
                                                                "
                                                            /><!----><span
                                                                class="el-input__suffix"
                                                                ><span
                                                                    class="el-input__suffix-inner"
                                                                    ><i
                                                                        data-v-59f23ef8=""
                                                                    ></i
                                                                    ><!----><!----><!----><!----></span
                                                                ><!----></span
                                                            ><!----><!---->
                                                        </div>
                                                    </div>
                                                </div>
                                                <!---->
                                            </div>
                                        </div>
                                    </el-form-item>
                                    <el-form-item label="" prop="smsCode">
                                        <div
                                            data-v-4b69f20a=""
                                            data-v-17f4985d=""
                                            class="el-input el-input--prefix el-input--suffix"
                                            style="width: 65%"
                                        >
                                            <!----><input
                                                autocomplete="new-password"
                                                placeholder="请输入验证码"
                                                class="el-input__inner"
                                                v-model="loginForm.smsCode"
                                            /><span class="el-input__prefix"
                                                ><img
                                                    data-v-4b69f20a=""
                                                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAATCAYAAACZZ43PAAAAAXNSR0IArs4c6QAAAZxJREFUOE+9kzFIW1EYhc95BLt1Sd69j+Kigw4ZMrh06dIKnboVcXBSRESRrnGxXXQtUilS2i4diujkJKiLi0uHDhl00EXk3fuSoW5KyCkXYkjSYEMDvdv9/3c+zv+/c4muIyny3s8AmCdZCm1JPwF8MsZ8I9lol7D9Imkoy7JdSa8ABNFRs/8CQInkfhzHr0ne3es6AN77TUlLAJattR/b4c65RQAfSG4ZY1b+ANRqteF6vX4paStJkjfdo4V7mqbvSS7lcrmRfD5/FWotB2mazpL8HEXReBzH570AWZaNNRqNM0lzSZJ86QB478uS1qvV6qNisdiasR1UqVSGCoXCLclVY8zGPzsgOWuM+doCNK1tAnjZy3qP2kEURSthVAaxpNOwD0k7AK7/AnlCcirEg+RTeu/3ADyXNGGtvejHgXNulOQPAMcB8EvSd2vtQj/i+2+cc9skp+mcE4C31tp33QDv/UmoGWOedfecc2tB9yCgCYe1tiOxAfZ/AH2NMPASB/6NAwcpbLQJCY9jUtLjh/JA8gbAIclyiPJvBN73/nFhKEMAAAAASUVORK5CYII="
                                                    alt=""
                                                /><!----></span
                                            >
                                        </div>
                                        <span
                                            data-v-17f4985d=""
                                            class="spanStyle"
                                            @click="startCountDown"
                                            >{{ showMessage }}</span
                                        >
                                    </el-form-item>

                                    <el-form-item label="" prop="pwd">
                                        <el-input
                                            type="password"
                                            v-model="loginForm.pwd"
                                            prefix-icon="el-icon-edit"
                                            show-password
                                            placeholder="请设置登录密码"
                                        ></el-input>
                                    </el-form-item>
                                    <el-form-item label="" prop="surePwd">
                                        <el-input
                                            type="password"
                                            show-password
                                            v-model="loginForm.surePwd"
                                            prefix-icon="el-icon-edit"
                                            placeholder="请确认登录密码"
                                        ></el-input>
                                    </el-form-item>
                                    <el-form-item>
                                        <div
                                            data-v-1075ceb3=""
                                            class="el-form-item forgetPwdSubmit"
                                        >
                                            <!---->
                                            <div class="el-form-item__content">
                                                <button
                                                    data-v-1075ceb3=""
                                                    type="button"
                                                    class="el-button el-button--primary"
                                                    @click.prevent="
                                                        goRegisterBtn
                                                    "
                                                >
                                                    <!----><!----><span
                                                        >注册</span
                                                    ></button
                                                ><!---->
                                            </div>
                                        </div>
                                    </el-form-item>
                                </el-form>
                            </div>
                        </div>
                        <!-- 登录 -->
                        <div
                            v-if="currentLoginType == '2'"
                            data-v-1075ceb3=""
                            role="tabpanel"
                            aria-hidden="true"
                            id="pane-password_login"
                            aria-labelledby="tab-password_login"
                            class="el-tab-pane"
                        >
                            <div
                                data-v-1075ceb3=""
                                class="password_login_box box"
                            >
                                <el-form
                                    ref="loginFormRef"
                                    :model="loginForm"
                                    class="login_form"
                                    :rules="loginRules"
                                >
                                    <el-form-item label="" prop="phone">
                                        <el-input
                                            v-model="loginForm.phone"
                                            prefix-icon="el-icon-user"
                                        ></el-input>
                                    </el-form-item>
                                    <el-form-item label="" prop="pwd">
                                        <el-input
                                            type="password"
                                            v-model="loginForm.pwd"
                                            prefix-icon="el-icon-edit"
                                            show-password
                                        ></el-input>
                                    </el-form-item>
                                    <el-form-item class="btns">
                                        <div data-v-17f4985d="" class="box_btn">
                                            <div
                                                data-v-17f4985d=""
                                                class="password_login_box_btn_top"
                                            >
                                                <div data-v-17f4985d="">
                                                    <el-checkbox
                                                        v-model="radio"
                                                        :checked="radio"
                                                        style="color: #bfbfbf"
                                                        >记住我</el-checkbox
                                                    >
                                                </div>
                                                <div data-v-17f4985d="">
                                                    <span
                                                        data-v-17f4985d=""
                                                        class=""
                                                        @click="goToForgetPwd"
                                                        >忘记密码</span
                                                    >
                                                    丨
                                                    <span
                                                        data-v-17f4985d=""
                                                        @click="
                                                            currentLoginType =
                                                                '1'
                                                        "
                                                        >注册</span
                                                    >
                                                </div>
                                            </div>
                                            <button
                                                data-v-17f4985d=""
                                                class="password_login_box_btn_bot"
                                                @click.prevent="loginBtn"
                                            >
                                                登录
                                            </button>
                                        </div>
                                    </el-form-item>
                                </el-form>
                                <div data-v-17f4985d="" class="box_logo">
                                    <div data-v-17f4985d="">
                                        <!-- <img
                                            data-v-17f4985d=""
                                            src="../../assets/loginImg/logo2.027ec776.png"
                                            alt=""
                                        /> -->
                                    </div>
                                    <div data-v-17f4985d="">
                                        <!-- <img
                                            data-v-17f4985d=""
                                            src="../../assets/loginImg/logo3.9537a993.png"
                                            alt=""
                                        /> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <a
                    data-v-1075ceb3=""
                    href="/home/index"
                    class="back router-link-active"
                >
                    <div data-v-1075ceb3="" class="backText">返回首页</div>
                    <img
                        data-v-1075ceb3=""
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAsCAYAAADretGxAAAAAXNSR0IArs4c6QAAAs5JREFUWEfFmEtrFEEUhc/x/X6gouID0QguBF24ERQEXbjxf/kb/AWKO10oqCAIusgmi/ggIiqJECRo1GDURI6cUCVlpaZ7psfpHmh6MUX31/ece+vWpaS1AEDyt+9d/BggNgBYJrnUNYRBfpD82TZIGomNANYDWCC52CZICuFIRJAvJBfaAjEEAWwCECF89zVH8msbIAaApHUAtoZIRAjfZ0nOjxpkBSKA+KU7E5AozQzJT6ME+QsRQCzL3kwam/UdyblRgfwDEUC2ADhQkOY1yY+jAFkFEUC2AThcAHlJcrYORJKldRX+Vbd2ZV2vRZJ2ADhW8MgkyQ9VD5dkP9lf3/tJ9Z4QISK7AZxIUthfaI9MkJyu+IAIYRCn+ucq6EqIALIHwKmCNOMk35ceLskGdyQN4ft0lZ9qIQLIPgCnC9I8I/k2B0nkMEQEmSI5U4LuCyKA7AdwNoA43FGaJyTfpA8PO7MjGCEiiGVcBd03RAA5COBcwSOPSU5lIJtDhuUgT/O1A0EEkEMAzhekeUDyVQZiP5wEsCuJynYAj0g+j2sHhgggRwFcKEhzj+SLDMSynMlADHeH5ERlnagrMpJcQy4VpLlLcjIDcQV29JzyadbcJDneKBLxBZKOA7iStQLekW8XInIEwOUMxNLcGAoiSDMG4GoGskjyeiF1DX0tgETDLv1viJi6dRA2qi9LszwUREM58kxpLkdDY+YAtxobU5JNdjFLURvyfo8UdaVNi5YN6SxqlqKSSsXKAA/7LFb2gdc2K1aSBinb3kndGKUSGKB52ZbU7QYmadCtPO0nht/KJTVpatLOarimRpL1dEX0Q9Meop32TpLD6E0qnsjiYaidRldSty2/pG4PP6FLjsfAdFTQzjEwnJpsxNwD7RyIOx8NSFqTNa3tD0mS6V06qZnv5wxZ14f2+38+ODPIt64HZ52PEDsfpnY6Vv4DFWRNzOsAcbgAAAAASUVORK5CYII="
                        alt=""
                    />
                </a>
            </div>
            <div class="indexInner"></div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            // 登录方式
            loginType: [
                {
                    id: "1",
                    name: "免费注册",
                },
                {
                    id: "2",
                    name: "登录",
                },
            ],
            currentLoginType: "1",
            loginForm: {
                phone: "",
                pwd: "",
                smsCode: "",
            },
            loginRules: {
                phone: [
                    {
                        required: true,
                        message: "请输入正确的账号",
                        trigger: "blur",
                        pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
                    },
                    {
                        min: 3,
                        max: 11,
                        message: "长度在 3 到 11 个字符",
                        trigger: "blur",
                    },
                ],
                pwd: [
                    {
                        required: true,
                        message: "请输入正确的密码",
                        trigger: "blur",
                    },
                    {
                        min: 3,
                        max: 11,
                        message: "长度在 3 到 11 个字符",
                        trigger: "blur",
                    },
                ],
                smsCode: [
                    {
                        required: true,
                        message: "请输入正确的验证码",
                        trigger: "blur",
                    },
                    {
                        min: 4,
                        max: 10,
                        message: "长度在 4 到 10 个字符",
                        trigger: "blur",
                    },
                ],
            },
            // 记住我
            radio: false,
            countDown: 60, // 倒计时时间（单位：秒）
            disabled: false, // 按钮是否可用
            showMessage: "获取验证码",
        };
    },
    methods: {
        // 改变登录方式
        changeLoginType(id) {
            this.currentLoginType = id;
        },
        // 注册
        goRegisterBtn() {
            // console.log("this.loginForm", this.loginForm);
            this.$refs.loginFormRef.validate(async (val) => {
                if (!val) return;
                // 手机号的判断
                let reg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
                if (!reg.test(this.loginForm.phone)) {
                    //判断如果手机号为空，提示用户输入手机号
                    this.$message.error("请输入正确的手机号");
                    return;
                }
                // 确认密码
                if (this.loginForm.pwd != this.loginForm.surePwd) {
                    this.$message.error("确认密码输入有误，请重新输入");
                    return;
                }
                const res = await this.$api.users.goRegister(this.loginForm);
                if (res.code == 200) {
                    this.$message.success("注册成功");
                    // 跳转到首页 不能回退
                    this.$router.replace("/login");
                }
            });
        },
        // 密码登录
        loginBtn() {
            console.log("this.radio", this.radio);
            this.$refs.loginFormRef.validate(async (val) => {
                if (!val) return;
                const res = await this.$api.users.login(this.loginForm);
                if (res.code == 200) {
                    //保存token 把后端返回的token保存到本地存储里面
                    localStorage.token = res.content.accessToken;
                    this.$message.success("登录成功");
                    // 跳转到首页 不能回退
                    this.$router.replace("/home/index");
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 记住我
        // changeRemember() {
        //     // this.radio = !this.radio;
        //     console.log("this.radio", this.radio);

        // },

        //获取验证码
        async getSmsCode(codeType) {
            let reg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
            if (!this.loginForm.phone) {
                this.$message.error("请输入手机号");
            } else if (!reg.test(this.loginForm.phone)) {
                this.$message.error("请输入正确的手机号");
            } else {
                // 发获取验证码的请求
                const res = await this.$api.users.sendSmsCode({
                    phone: this.loginForm.phone,
                    codeType,
                });
                if (res.code == "200") {
                    this.$message.success("验证码已发送");
                    // const res = await this.$api.users.getSmsCode({
                    //     phone: this.loginForm.phone,
                    //     codeType,
                    // });
                    // if (res == "200") {
                    //     this.loginForm.smsCode = res.content;
                    // }
                    // console.log('res1111',res);
                } else {
                    this.$message.error(res.message);
                }
            }
        },
        async startCountDown() {
            if (this.loginForm.phone) {
                if (this.disabled) return;
                this.disabled = true; // 禁用按钮
                await this.getSmsCode("3");
                let timer = setInterval(() => {
                    if (this.countDown > 0) {
                        this.countDown--;
                        this.showMessage = this.countDown + "秒后重发";
                    } else {
                        clearInterval(timer);
                        this.disabled = false; // 启用按钮
                        this.countDown = 60; // 重置倒计时时间
                        this.showMessage = "获取验证码";
                    }
                }, 1000);
            }else{
                this.$message.error("请输入正确的手机号");
            }
        },
        goToForgetPwd() {
            this.$router.push("/forgetpassword");
        },
        goToRegister() {
            this.$router.push("/register");
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../styles/app.4bd1b4ff.css";
@import "../../styles/chunk-vendors.eb0aa7b3.css";
.login {
    width: 100vw;
    height: 100vh;
    background: url("../../assets/loginImg/1609405666_305760.jpg") no-repeat !important;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-position: center center !important;
    position: relative;
}
.indexInner {
    background: rgba(0, 0, 0, 0.4);
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
}
.left_logo {
    z-index: 3;
}
.login_box {
    z-index: 3;
}
.login_form {
    height: 80%;
}
.password_login_box_btn_bot {
    border: none;
}
.login .login_box .box .forgetPwdForm .pwd[data-v-1075ceb3] {
    margin-bottom: 0px;
}
.spanStyle[data-v-17f4985d] {
    display: inline-block;
    width: 100px;
    height: 48px;
    background: #1ab6ff;
    border-radius: 10px;
    cursor: pointer;
    float: right;
    font-size: 16px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    color: #fff;
    line-height: 22px;
    text-align: center;
    line-height: 48px;
}
.el-form-item__content {
    margin-top: 0 !important;
}
.el-form-item {
    margin-bottom: 14px;
}
.el-form-item:nth-child(4) {
    margin-bottom: 0px;
}
</style>